import React, {
  Component
} from 'react';
import Project from './Project';

const projects = [
{ name : 'GotTea Website', img : 'GotTea.jpeg', description : <span>Built a product website <a href='https://gottea.in'>gottea.in</a> for GotTea ,a bubble tea company based out of New Delhi, India, using React + Redux + Less on the frontend with a Firebase backend.</span>},
{ name : 'Herringbone and Sui Website', img : 'HandS.jpeg', description : <span>Updated and lead the engineering team responsible for maintaining the Herringbone and Sui E - Commerce website <a href='https://herringboneandsui.com'>herringboneandsui.com</a> that offers bespoke Luxury Menswear.</span>},
{ name : 'Herringbone and Sui Style App', img : 'StyleApp.jpeg', description : <span>Updated and lead the engineering team responsible for maintaining the Herringbone and Sui In Store style app  <a href='https://style.herringboneandsui.com'>style.herringboneandsui.com</a>. The web app is used internally for processing and tracking sales and offered as a SASS product to other businesses on a B2B basis.</span>},
{ name : 'VoiceIt Dashboard', img : 'VoiceItDashboard.jpeg', description : <span>Built an interactive dashboard for VoiceIt at <a href='https://dashboard.voiceit.io'>dashboard.voiceit.io</a> using React + Redux on the frontend and NodeJS + Express on the backend. This dashboard allows VoiceIt's 2000+ developers view graphs, and manage their activity and users. The dashboard also contains a support backend that enables the VoiceIt support team to narrow down customer issues, and analyze their activity to provide rapid and pro-active support.</span>},
{ name : 'VoiceIt Website', img : 'VoiceItWebsite.jpeg', description : <span>Designed the official VoiceIt website at <a href='https://voiceit.io'>voiceit.io</a> using bootstrap, jQuery and a Java/JSP backend. This website is frequented by over 3000 visitors a week, and is used by developers around the world to sign up and get started using VoiceIt's biometric services.</span>},
{ name : 'Mighty Ole', img : 'MightyOle.jpeg', description : <span>One of the most fun endless runner games. Lead the famous Ole Lion traversing through picturesque Minnesota landscapes, collecting Caf mugs and fighting the nasty squirrels. Co - designed and developed with <a href="http://drewvolz.com">Drew Volz</a>. Play now <a href="https://mightymusketeers.github.io">here</a>.</span>},
{ name : 'Liovinci App', img : 'Liovinci.jpeg', description : <span>Led the development of the iOS app Liovinci and supporting website, that helped enthusiasts track products, and keep up to date with their latest news and rumours. App received over 5000 downloads. Visit the website at <a href="http://liovinci.com">liovinci.com</a> to learn more.</span>},
{ name : 'Squishy Tomatoes', img : 'SquishyTomatoes.jpeg', description : <span>Singlehandedly designed an iOS game in Swift using Sprite Kit. Game received over 800 downloads. Visit the facebook page at <a href="https://www.facebook.com/squishytoms/">facebook.com/squishytoms</a> to learn more. Or download now for your iOS device <a href="https://itunes.apple.com/us/app/squishy-tomatoes/id898402233?mt=8">here</a>.</span>},
{ name : 'Ancient History Bulletin', img : 'AHB.jpeg', description : <span>Helped St. Olaf professer Timothy Howe with launchin an online portal and store for the Ancient History Bulletin. Visit the website at <a href="https://ancienthistorybulletin.org">ancienthistorybulletin.org</a> to learn more.</span>} 
];

class Projects extends Component {

  renderProjects = () => {
    return projects.map((item, index)=>{
      return (
        <div className = "threeColumn"  key={`project${index}`}>
          <Project
           
            imageName = {item.img}
            projectTitle = {item.name}
            description = {item.description}
          />
        </div>
        )
    });
  }

  render() {
    return (
      <div>
      <h1 className = "titleSize">Intergalactic Missions</h1>
      <div
      className = "titleUnderline"
      style = {{ marginBottom: '20px' }}
      >
      </div>
      <p className = "subtitle" > Explore some of my favorite missions across the universe </p>
      <header className = "section-header threeColumnGrid" >
        {this.renderProjects()}
      </header>
      </div>
    );
  }
}

export default Projects;
