import React, { Component } from 'react';
import logo from './images/logo.svg';
import profileImage from './images/Me.png';
import './css/App.css';
import Skills from './Skills';
import Projects from './Projects';
import BackgroundStars from './BackgroundStars';
import SpaceShip from './SpaceShip';
import Footer from './Footer';

class App extends Component {

  // constructor(){
  //   super();
  //   // this.disableScrolling();
  // }

  // disableScrolling = () => {
  //     var x=window.scrollX;
  //     var y=window.scrollY;
  //     window.onscroll=function(){window.scrollTo(x, y);};
  // }

  render() {

    return (
      <div className="App">
          <BackgroundStars/>
          <div className="firstSection" id="introHeader">
          <header className="section-header">
          <div className="logoContainer vibration">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
            <h2 className="intro introTitle">Fullstack developer, JavaScript lover and A.I. enthusiast</h2>
            <p className="intro introSubtitle">I like to code and build beautifully functional and simple things, and I love what I do.</p>
            <SpaceShip to="aboutMe" offset={150}/>
          </header>
          </div>
          <div className='fullHeight' id="aboutMe">
          <header className="section-header">
            <div className="logoContainer">
              <img src={profileImage} className="profileImage" alt="Armaan Bindra"/>
            </div>
            <h2 className="intro introTitle">Hi, I'm Armaan. Reporting from planet Earth</h2>
            <p className="intro introSubtitle">I am an energetic and enthusiastic software and web developer from India. I spend my time solving difficult problems, brainstorming innovative app ideas, learning the latest technologies and using my super powers to build cool things. At the moment, I specialize in developing mobile apps writing code natively in <span className="highlighted">Swift</span> or <span className="highlighted">Java</span>, or building cross-platform apps using <span className="highlighted">React Native</span>. I also enjoy building websites fueled by <span className="highlighted">React</span> and <span className="highlighted">Node</span>.</p>
            <SpaceShip to="spaceGear" offset={150}/>
          </header>
          </div>
          <div className='fullHeight' id="spaceGear">
            <Skills/>
            <SpaceShip to="missions" offset={190}/>
          </div>
          <div className='fullHeight' id="missions">
            <Projects/>
            <SpaceShip to="introHeader" offset={0} direction="up"/>
          </div>
          <Footer/>
      </div>
    );
  }
}

export default App;
