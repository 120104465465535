import React, { Component } from 'react';

class Modal extends Component {
  render() {
    return (
      <div onClick={this.props.onClick} className={`projectModal ${this.props.open ? 'modalOpen' : 'modalClosed'}`} >
        <div className='closeIcon' onClick={this.props.onCloseModal}/>
        <p className="modalTitle">{this.props.projectTitle}</p>
        <div className="modalUnderline"/>
        <img className="modalImage" src={`images/${this.props.imageName}`} alt={this.props.imageName}/>
        <p className="modalDescription">{this.props.projectDescription}</p>
      </div>
    );
  }
}

export default Modal;
