import React, { Component } from 'react';
import SkillSet from './SkillSet';

class Skills extends Component {
  render() {
    return (
      <div>
        <h1 className="titleSize">Alien Languages I Speak</h1>
        <div className="titleUnderline"></div>
        <p className="subtitle">These are some of the languages I practice frequently,<br/>listed in order of fluency</p>

              <header className="section-header threeColumnGrid">
                <div className="threeColumn">
                <div className="skillContent">
                  <SkillSet
                    data={[
                      {percent: 100, name: 'Vanilla JavaScript'},
                      {percent: 100, name: 'HTML5 + CSS3'},
                      {percent: 100, name: 'React  + Redux'},
                      {percent: 95, name: 'C++'},
                      {percent: 95, name: 'Python'},
                      {percent: 85, name: 'Swift'},
                      {percent: 85, name: 'NodeJS + Express'},
                    ]}
                  />
                </div>
                </div>
                <div className="threeColumn">
                <div className="skillContent connectedSkills">
                  <SkillSet
                    data={[
                      {percent: 80, name: 'React Native'},
                      {percent: 80, name: 'Java'},
                      {percent: 80, name: 'WordPress'},
                      {percent: 75, name: 'SQL'},
                      {percent: 70, name: 'PHP'},
                      {percent: 65, name: 'AWS'},
                      {percent: 60, name: 'Git'},
                    ]}
                  />
                </div>
                </div>
                <div className="threeColumn">
                <div className="skillContent connectedSkills">
                  <SkillSet
                    data={[
                      {percent: 65, name: 'Sketch / Figma'},
                      {percent: 60, name: 'Adobe PS'},
                      {percent: 60, name: 'Deep Learning'},
                      {percent: 60, name: 'Linux'},
                      {percent: 40, name: 'GoLang'}
                    ]}
                  />
                </div>
                </div>
              </header>
      </div>
    );
  }
}

export default Skills;
