import React, { Component } from 'react';
import { Link } from 'react-scroll';

class Spaceship extends Component {
  // enableScrolling = () => {
  //     window.onscroll=function(){};
  // }

  render() {
    const shipThis = this;
    return (
      <Link to={shipThis.props.to} spy={true} smooth={true} offset={shipThis.props.offset} duration={1400}>
      <div
        onClick={function(event){
          // shipThis.enableScrolling();
          var ship = event.target;
          var audio = new Audio('audio/spaceLaunch.wav');
          audio.volume = 0.1;
          audio.play();
          ship.classList.add(shipThis.props.animationClass || 'launchShip');
          setTimeout(function(){
            ship.classList.remove(shipThis.props.animationClass || 'launchShip');
          },1500);
        }}
        className={`shipArrow ${shipThis.props.direction === 'up' ? 'upShip' : 'downShip'}`}>
        </div>
      </Link>
    );
  }
}

export default Spaceship;
