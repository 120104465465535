import React, { Component } from 'react';
import Modal from './Modal';

class Project extends Component {

  constructor(){
    super();
    this.state ={
      open:false
    }
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = (e) => {
    e.stopPropagation();
    this.setState({ open: false });
    console.log(this.state);
  };


  render() {
    return (
      <div onClick={this.onOpenModal} className="projectContent" style={{'backgroundImage':`url(images/${this.props.imageName})`}}>
        <p className="projectTitle">{this.props.projectTitle}</p>
        <Modal
        open={this.state.open}
        onCloseModal={this.onCloseModal}
        projectTitle={this.props.projectTitle}
        imageName={this.props.imageName}
        projectDescription={this.props.description}
        className="projectModal"
        />
      </div>
    );
  }
}

export default Project;
