import React, { Component } from 'react';
import Progress from 'react-progressbar';

class SkillSet extends Component {
  render(){
    const skills = this.props.data.map((skill, index)=>{
      return (
        <Progress key={`prog${index}`} className={`progressBar ${skill.percent === 100 ? 'fullBar' : ''}`} completed={skill.percent} >
        <p className='progressText'>{skill.name}</p>
        </Progress>
      );
    });
    return skills;
  }
}

export default SkillSet;
