import React, { Component } from 'react';
import SocialIcon from './SocialIcon';
class Footer extends Component {
  render() {
    return (
      <footer>
        <SocialIcon type="github" link="https://github.com/armaanbindra"/>
        <SocialIcon type="linkedin" link="https://www.linkedin.com/in/armaan-bindra-00483411"/>
        <SocialIcon type="facebook" link="http://facebook.com/armaan.bindra"/>
        <p>Handcrafted by me &copy; 2020</p>
        <div className="credit">Rocket icon made by <a href="https://www.freepik.com/" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" 	title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" >CC 3.0 BY</a></div>
      </footer>
    );
  }
}

export default Footer;
