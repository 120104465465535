import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);

class SocialIcon extends Component {
  render() {
    return (
      <div className='socialIcon'>
        <a href={this.props.link} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={['fab', this.props.type]} />
        </a>
      </div>
    );
  }
}

export default SocialIcon;
